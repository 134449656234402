<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="pb-1 pt-1">
        <v-text-field
          ref="searchInput"
          hide-details
          label="Filtrar cliente"
          dense
          outlined
          prepend-inner-icon="search"
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="pb-1 pt-1">
        <v-select
          dense
          :items="orderByFilter"
          label="Ordenar por"
          hide-details
          outlined
        ></v-select>
      </v-col>
      <v-col cols="12" class="pb-1 pt-1 justify-space-between d-flex">
        <v-btn class="mr-2" v-if="$vuetify.breakpoint.mdAndUp"
          ><v-icon small class="mr-2">star_border</v-icon> Favoritos</v-btn
        >
        <v-btn class="mr-2" v-else><v-icon>star_border</v-icon></v-btn>
        <v-btn color="success">Agregar cliente</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      orderByFilter: ["Foo", "Bar", "Fizz", "Buzz"]
    };
  }
};
</script>

<style></style>
