<template>
  <v-container
    class="pt-0 pb-0"
    :class="{ 'pr-0 pl-0': !$vuetify.breakpoint.mdAndUp }"
  >
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="9" xl="9" class="pt-0 pb-0">
        <v-card class="mb-2" v-if="!$vuetify.breakpoint.lgAndUp">
          <app-customer-header></app-customer-header>
        </v-card>
        <v-card>
          <v-row>
            <template v-for="(customer, index) in customerData">
              <v-col
                cols="12"
                class="pb-0 pt-0"
                :key="index"
                @click="getDetailCustomer(customer.id)"
              >
                <app-card-list-customer
                  :customerName="customer.name"
                  :customerNote="customer.note"
                  :customerOrders="customer.totalOrders"
                  :customerMeanTicket="customer.meanTicket"
                ></app-card-list-customer>
              </v-col>
              <v-col
                cols="12"
                class="pb-1 pt-1"
                :key="index + '-divider'"
                v-if="customerData.length != index + 1"
              >
                <v-divider></v-divider>
              </v-col>
            </template>
          </v-row>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        lg="3"
        xl="3"
        v-if="$vuetify.breakpoint.lgAndUp"
        class="pt-0 pb-0"
      >
        <v-card>
          <app-customer-header></app-customer-header>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardListCustomer from "@/components/customers/card-list-customer";
import CustomerHeader from "@/components/customers/Header";

export default {
  components: {
    AppCardListCustomer: CardListCustomer,
    AppCustomerHeader: CustomerHeader
  },
  data() {
    return {
      customerData: [
        {
          name: "Maria Dulce",
          note: "Nota de maria",
          totalOrders: 10,
          meanTicket: 23,
          id: "12312"
        },
        {
          name: "Maria Dulce",
          note: "Nota de maria",
          totalOrders: 10,
          meanTicket: 23,
          id: "12312"
        }
      ]
    };
  },
  methods: {
    getDetailCustomer(userID) {
      this.$router.push({ name: "customerDetail", params: { uid: userID } });
    }
  }
};
</script>
