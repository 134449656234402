<template>
  <v-container class="pb-1 pt-1 hover-and-click-list-element">
    <v-row align="center">
      <v-col cols="12" sm="12" md="7" lg="7" xl="7" class="pt-0 pb-0">
        <div class="text-h6">
          {{ customerName }} <v-icon color="warning">star</v-icon>
        </div>
        <div class="text-body-2">
          <v-icon class="mr-1">note</v-icon>{{ customerNote }}
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="2"
        lg="2"
        xl="2"
        class="pt-0 pb-0  text-body-2"
      >
        {{ customerOrders | filterOrders }}</v-col
      >
      <v-col
        cols="12"
        sm="12"
        md="3"
        lg="3"
        xl="3"
        class="pt-0 pb-0 text-body-2"
        >Ticket medio $ {{ customerMeanTicket }}</v-col
      >
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    customerName: {
      type: String,
      default: () => ""
    },
    customerNote: {
      type: String,
      default: () => ""
    },
    customerOrders: {
      type: Number,
      default: () => 0
    },
    customerMeanTicket: {
      type: Number,
      default: () => 0
    }
  },
  filters: {
    filterOrders(value) {
      if (value == 1) {
        return value + " Orden";
      } else {
        return value + " Ordenes";
      }
    }
  }
};
</script>

<style scoped>
.hover-and-click-list-element:hover,
.hover-and-click-list-element:active {
  cursor: pointer;
  background-color: #eaeaea;
}
</style>
